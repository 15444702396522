import { Link } from 'gatsby'
import React from 'react'

const Footer = props => (
  <footer id="footer">
    {/* <section>
      <h2>Aliquam sed mauris</h2>
      <p>
        Sed lorem ipsum dolor sit amet et nullam consequat feugiat consequat
        magna adipiscing tempus etiam dolore veroeros. eget dapibus mauris. Cras
        aliquet, nisl ut viverra sollicitudin, ligula erat egestas velit, vitae
        tincidunt odio.
      </p>
      <ul className="actions">
        <li>
          <Link to="/generic" className="button">
            Learn More
          </Link>
        </li>
      </ul>
    </section> */}
    {/* <section id="cta">
      <h2>Contact</h2>
      <dl className="alt">
        <dt>Address</dt>
        <dd>2 Taylorwood Rd &bull; Oshawa, ON L1G 3Z2 &bull; Canada</dd>
        <dt>Phone</dt>
        <dd>(289) 943-2722</dd>
        <dt>Email</dt>
        <dd>
          <a href="#">segoviatyler@gmail.com</a>
        </dd>
        <dt>LinkedIn</dt>
        <dd>
          <a href="https://www.linkedin.com/in/tylersegovia" target="_blank">linkedin.com/in/tylersegovia/</a>
        </dd>
        <dt>GitHub</dt>
        <dd>
          <a href="https://github.com/Termina1Zer0" target="_blank">github.com/Termina1Zer0</a>
        </dd>
      </dl>
    </section> */}
    <p className="copyright">
      &copy; Tyler Segovia, 2021. &nbsp; Starter Template: <a href="https://html5up.net" target="_blank">HTML5 UP</a>.
    </p>
  </footer>
)

export default Footer
