import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import pic01 from '../assets/images/pic01.jpg'
import Header from '../components/Header'
import Layout from '../components/layout'
import Nav from '../components/Nav'
import Scroll from '../components/Scroll'
// import resume from '../assets/files/TylerSegovia_Resume.pdf'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet title="Tyler Segovia" />

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Get To Know Me</h2>
                </header>
                <p>
                  I'm a <a href="https://durhamcollege.ca/">Durham College</a> 2021 graduate with an Advanced
                  Diploma in Computer Programming and Analysis. I have
                  a solid foundation in Software Development, with a
                  particular passion for Full-Stack Web Development.
                </p>
                <ul className="actions">
                  <li>
                    <Scroll type="id" element="cta" >
                      <a className="button special">Hire Me</a>
                    </Scroll>
                  </li>
                  <li>
                    <a href="https://drive.google.com/file/d/1JdPvwT-8MRYxOGCh3tqOo6at0iGP9BQN/view?usp=sharing" target="_blank" rel="noreferrer" className="button">Download Resume</a>
                  </li>
                </ul>
              </div>
              <span className="image">
                <img src={pic01} alt="" />
              </span>
            </div>
          </section>

          <section id="first" className="main special">
            <header className="major">
              <h2>My Skills</h2>
            </header>
            <p>
              I bring enthusiasm and detailed understanding of various
              programming languages to website planning, development,
              and maintenance. I am motivated to learn, grow, and
              excel; eager to contribute to team success through hard
              work, attention to detail, and exceptional organizational
              skills.
            </p>
            <div className="container">
              <div className="skills">
                <p>HTML/HTML5</p>
              </div>
              <div className="skills">
                <p>CSS/CSS3</p>
              </div>
              <div className="skills">
                <p>PHP</p>
              </div>
              <div className="skills">
                <p>JavaScript</p>
              </div>
              <div className="skills">
                <p>React</p>
              </div>
              <div className="skills">
                <p>C#</p>
              </div>
              <div className="skills">
                <p>C++</p>
              </div>
              <div className="skills">
                <p>SQL</p>
              </div>
              <div className="skills">
                <p>Bootstrap</p>
              </div>
              <div className="skills">
                <p>WordPress</p>
              </div>
              <div className="skills">
                <p>Microsoft .NET framework</p>
              </div>
              <div className="skills">
                <p>GitHub</p>
              </div>
            </div>
          </section>

          <section id="second" className="main special">
            <header className="major">
              <h2>Experience</h2>
            </header>
            <div className="timeline">
              <div className="entry">
                <div className="title">
                  <h3>Apr 2021 - May 2021</h3>
                  <p>AllAboutWebServices.com</p>
                </div>
                <div className="info">
                  <p>Web Developer Intern</p>
                  <ul>
                    <li>Provided front-end website development using WordPress and other editing software with minimum supervision.</li>
                    <li>Collaborated with technical lead on project specifications, strategy and execution to build new WordPress pages and update existing ones.</li>
                    <li>Installed required plugins and additional coding to fit final design and functional requirements.</li>
                  </ul>
                </div>
              </div>
              <div className="entry">
                <div className="title">
                  <h3>Jul 2019 - Aug 2019</h3>
                  <p>Caribbean Blue Naturals &reg;</p>
                </div>
                <div className="info">
                  <p>Web Developer</p>
                  <ul>
                    <li>Provided front-end website development using Wix and other software.</li>
                    <li>Designed, implemented and monitored web pages and plugins to support an online store featuring over 60 products.</li>
                    <li>Employed search engine optimization tactics to increase reach of targeted audience.</li>
                  </ul>
                </div>
              </div>
              <div className="entry">
                <div className="title">
                  <h3>May 2019 - Jun 2019</h3>
                  <p>TED Co.</p>
                </div>
                <div className="info">
                  <p>Database Developer</p>
                  <ul>
                    <li>Coordinated with project management staff on database development timelines and project scope.</li>
                    <li>Wrote and maintained technical and functional specifications to document database intentions and requirements.</li>
                    <li>Developed, updated and organized Microsoft Access databases to handle customer and tour data.</li>
                  </ul>
                </div>
              </div>
              <div className="entry">
                <div className="title">
                  <h3>Aug 2017 - Jul 2018</h3>
                  <p>Natmed Ltd.</p>
                </div>
                <div className="info">
                  <p>IT Assistant</p>
                  <ul>
                    <li>Reviewed system and network performance to make proactive adjustments based on established IT policies.</li>
                    <li>Installed and configured software and hardware.</li>
                    <li>Analyzed software and hardware issues to identify troubleshooting methods needed for quick remediation.</li>
                  </ul>
                </div>
              </div>
              <div className="entry">
                <div className="title">
                  <h3>Feb 2017 - May 2017</h3>
                  <p>West Technology Group Inc.</p>
                </div>
                <div className="info">
                  <p>IT Technician Intern</p>
                  <ul>
                    <li>Collaborated with IT Technicians to troubleshoot hardware, software and network problems.</li>
                    <li>Investigated and corrected problems with computers, printers, copiers and other peripheral devices.</li>
                    <li>Removed malware, ransomware and other threats from laptops and desktop systems.</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section id="third" className="main special">
            <header className="major">
              <h2>Education</h2>
            </header>
            <div className="timeline">
              <div className="entry">
                <div className="title">
                  <a href="https://durhamcollege.ca/" target="_blank" rel="noreferrer"><h3>Durham College</h3></a>
                  <p>Apr 2021</p>
                </div>
                <div className="info">
                  <p>Advanced Diploma</p>
                  <ul>
                    <li>Graduated on the school Honour Roll with an Advanced Diploma in Computer Programming and Analysis.</li>
                  </ul>
                </div>
              </div>
              <div className="entry">
                <div className="title">
                  <h3>International School <br />Saint Lucia</h3>
                  <p>Jun 2017</p>
                </div>
                <div className="info">
                  <p>High School Diploma</p>
                  <ul>
                    <li>Graduated on the school Honour Roll with a Canadian High School Diploma and received the Academic Award for English and Business.</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section id="cta" className="main special">
            <header className="major">
              <h2>Contact</h2>
              <p>
                I'd Love To Connect With You
              </p>
            </header>
            <ul className="features">
              <li>
                <span className="icon major style1 fa-location-arrow"></span>
                <h3>Address</h3>
                <p>
                  Oshawa, Ontario<br />Canada
                </p>
              </li>
              <li>
                <span className="icon major style3 fa-envelope-o"></span>
                <h3>Email</h3>
                <p>
                  <a href="mailto: segoviatyler@gmail.com">segoviatyler@gmail.com</a>
                </p>
              </li>
              <li>
                <span className="icon major style5 fa-linkedin"></span>
                <h3>LinkedIn</h3>
                <p>
                  <a href="https://www.linkedin.com/in/tylersegovia" target="_blank" rel="noreferrer">linkedin.com/in/tylersegovia/</a>
                </p>
              </li>
              <li>
                <span className="icon major style7 fa-github"></span>
                <h3>GitHub</h3>
                <p>
                  <a href="https://github.com/Termina1Zer0" target="_blank" rel="noreferrer">github.com/Termina1Zer0</a>
                </p>
              </li>
            </ul>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Index
