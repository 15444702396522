import React from 'react'

import logo from '../assets/images/logo.svg';

const Header = (props) => (
    <header id="header" className="alt">
        {/* <span className="logo"><img src={logo} alt="" /></span> */}
        <h1>Tyler Segovia</h1>
        <p>Web Developer &nbsp; | &nbsp; Software Developer</p>
    </header>
)

export default Header
